import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingIso37001Page() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'ISO 37001'

  const description = ''

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
